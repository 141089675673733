// Debug 
//const url_debug = "http://192.168.2.11"
const url_debug = 'http://localhost:17807';
//const url_debug = "http://2.235.191.48"
//const url_debug = "https://services.i2k.it"




export default {

  getUrl() {
    if (window.location.host.includes("localhost"))
      return url_debug

    return window.location.protocol + "//" + window.location.host
  },

  getUrlApi() {

    let url = this.getUrl();

    url += "/api"

    return url

  },

  getUrlImg() {

    let url = this.getUrl();

    return url + "/risorse/img";
  },

  getUrlUpdate() {

    let url = this.getUrl();

    if (window.location.host.includes("localhost"))
      url = ""
    else
      url += "/index.html"

    return url
  },

}