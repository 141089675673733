export default {
    methods: {
        getHeadersDati() {
            return [{
                text: "Numero documento",
                value: "nrDocu"
            },
            {
                text: "Data documento",
                //value: "dtDocu",
                value: "dataFormat",
                sortable: true,
                sort: (a, b) => {
                    return new Date(b) - new Date(a);
                }
            },
            {
                text: "Posizioni",
                value: "numRighe"
            },
            {
                text: "Cliente",
                value: "ragSoc"
            },
            {
                text: "Numero fattura",
                value: "nrFattura"
            },
            {
                text: "Data fattura",
                value: "dtFattura"
            },
            {
                text: "Qta",
                value: "qtaTot"
            },
            {
                text: "Importo",
                value: "importoTot"
            },
            {
                text: "IVA",
                value: "TotIva"
            },
            {
                text: "Azioni",
                value: "",
                sortable: false
            },
            ]
        },

        getHeadersDatiGruppo() {
            return [
                {
                    text: "Posizioni",
                    value: "numRighe"
                },
                {
                    text: "Cliente",
                    value: "ragSoc"
                },
                {
                    text: "Importo",
                    value: "importoTot"
                },
                {
                    text: "IVA",
                    value: "TotIva"
                },
                {
                    text: "Azioni",
                    value: "",
                    sortable: false
                },
            ]
        },

        getHeadersDettaglio(raggruppa) {
            let ret = [];
            if (raggruppa) {
                ret.push({
                    text: "Documento",
                    value: "nrDocu",
                    class: "pl-0"
                }, {
                    text: "Data",
                    value: "dtDocu",
                    class: "pl-0"
                });
            }
            ret.push({
                text: "Riga",
                value: "nrRigaDocu",
                class: "pl-0 pr-0"
            },
                {
                    text: "Tipo riga",
                    value: "tpRiga",
                    class: "pl-0"
                },
                {
                    text: "Cod. Articolo",
                    value: "cdArticoloPadre",
                    class: "pl-0"
                },
                {
                    text: "Descrizione",
                    value: "descrizioneProdotto",
                    class: "pl-0"
                },
                {
                    text: "U. M.",
                    value: "unMisura"
                },
                {
                    text: "Qta ordinata",
                    value: "qtOrdinata",
                    class: "pl-0"
                },
                {
                    text: "Prezzo unitario",
                    value: "prUnitario",
                    class: "pl-0"
                },
                {
                    text: "Sconto 1",
                    value: "sconto1",
                    class: "pl-0 pr-0"
                },
                {
                    text: "Sconto 2",
                    value: "sconto2",
                    class: "pl-0 pr-1"
                },
                {
                    text: "Totale rigo",
                    value: "totRigo",
                    class: "pl-0"
                },
                {
                    text: "IVA",
                    value: "cdIva",
                    class: "pl-0"
                },
                {
                    text: "No bolla",
                    value: "noBolla",
                    class: "pl-0"
                },
                {
                    text: "No fattura",
                    value: "noFattura",
                    class: "pl-0 pr-0"
                },
            )
            return ret;
        },
    }
}