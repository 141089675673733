<template src="./archivio.html"></template>

<script>
import JSZip from 'jszip';
import { get_file } from "@/api/api.archivio.js"

export default {
    data() {
        return {
            tab: "icons",
            icons: [], // Array per memorizzare le informazioni delle icone
            uploadDialog: false, // Mostra/nasconde il dialogo di caricamento delle icone
            selectedFiles: [],
        };
    },
    methods: {
        // Funzione per gestire il cambio dei file durante il caricamento
        handleFileChange(event) {
            this.selectedFiles = Array.from(event.target.files);
        },
        // Funzione per caricare le icone sul server
        uploadIcons() {
            this.selectedFiles.forEach((file) => {
                // Puoi inviare il file al server qui (utilizzando axios o un'altra libreria)
                // Esegui le azioni necessarie per il caricamento del singolo file
                console.log('Caricamento file:', file.name);
            });

            this.selectedFiles = [];

            this.uploadDialog = false;
        },
        // Funzione per ottenere l'elenco delle icone dal server
        async getIcons() {
            let res = await get_file("icone");
            this.icons = res.data;
        },

        async downloadIcon(icon) {
            /* const link = document.createElement('a');
            link.style.display = 'none';
            link.href = icon.preview;
            link.download = icon.name;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(icon.preview);
            document.body.removeChild(link); */

            const zip = new JSZip();
            const response = await fetch(icon.preview);
            const blob = await response.blob();
            zip.file(icon.name, blob);

            zip.generateAsync({ type: 'blob' }).then((content) => {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(content);
                a.style.display = 'none';
                a.href = URL.createObjectURL(url);
                a.download = 'archivio.zip';
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            });
        },
    },

    mounted() {
        // Chiamare la funzione per ottenere l'elenco delle icone al caricamento della pagina
        this.getIcons();
    },
};

</script>
